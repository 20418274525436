<template>
  <ReturnGoodsCreationSection
          :RG="RG"
          :optionsStorage="optionsStorage"
          @save="save"
  />
</template>

<script>
  import ReturnGoodsCreationSection from "./ReturnGoodsCreationSection/ReturnGoodsCreationSection.vue";
  import {ReturnGoods} from "../models/ReturnGoods";
  import {returnGoodsMixin} from "@/mixins/returnGoodsMixins/returnGoodsMixin";

  export default {
    name: "ReturnGoodsCreation",

    components:{
      ReturnGoodsCreationSection,
    },

    mixins: [returnGoodsMixin],

    data() {
      return {
        RG: new ReturnGoods(),
        optionsStorage: [],
      }
    },

    mounted() {
      if(this.$route.query.returnFBMId){
        this.$store.dispatch('getOrdersFBM', this.$route.query.returnFBMId).then(response => {
          let fbmData = this.getRespData(response)?.orderFbmInstance

          this.RG.data.User.setUser(fbmData?.user)
          this.RG.data.trackingNumberObject = fbmData
          this.RG.data.trackingNumber = fbmData.tracking_number
        })
      }

      this.RG.data.shippedDate = this.$moment(new Date()).format("YYYY-MM-DD")
      this.RG.data.comment = this.$t('returnGoods_commentDefault.localization_value.value')

      this.getWarehouseStorages()
    },

    methods: {
      async getWarehouseStorages() {
        return this.$store.dispatch('fetchWarehouseStorage').then((response) => {
          this.optionsStorage = this.getRespPaginateData(response)
        })
      },
    }

  }
</script>

<style scoped>

</style>
